import axios from "axios";
export const students = {
  state: () => ({
    students: [],
    masters: [],
    oneStudent: {
      studyId: "",
      img: "",
    },
    study: [
      "Umumiy o`rta maktab",
      "Kasb-hunar kolleji",
      "Akademik litsey",
      "Universitet",
    ],
    studyName: [
      "Umumiy o`rta maktab",
      "Kasb hunar kolleji",
      "Akademik litsey",
      "Oliy ta`lim muassasi",
    ],
    types: [
      { title: "Kunduzgi", value: 0 },
      { title: "Sirtqi", value: 1 },
      // { title: 'Kechki', value: 2 },
    ],
    foreign: ["Ingliz tili", "Nemis tili", "Fransuz tili"],
    country: [
      { name: "O'zbekiston Respublikasi", code: "uz" },
      { name: "Qozog'iston Respublikasi", code: "kz" },
      { name: "Tojikiston Respublikasi", code: "tj" },
      { name: "Turkmaniston Respublikasi", code: "tk" },
      { name: "Qirg'iziston Respublikasi", code: "kg" },
      { name: "Boshqa", code: "ot" },
    ],
    countstudent: 0,
    uncountstudent: 0,
    countMaster: 0,
    chartResult: [[], []],
    magchartResult: [[], []],

    findresult: [],
    provinceResult: [
      {
        counts: [
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
          { counts: [0, 0] },
        ],
      },
    ],
    student: {},
    studyPart: ["Bakalavr", "Magistr"],
    languages: [
      { title: "O'zbekcha", value: 0 },
      { title: "Ruscha", value: 1 },
    ],
    statusList: [
      {
        title: "Qabul qilindi",
        type: "success",
      },
      {
        title: "Tekshiruvda",
        type: "warning",
      },
      {
        title: "Bekor qilindi",
        type: "danger",
      },
      {
        title: "Arxivda",
        type: "warning",
      },
      {
        title: "Talaba",
        type: "success",
      },
      {
        title: "",
        type: "info",
      },
      {
        title: "Kutilmoqda",
        type: "",
      },
    ],
  }),

  getters: {
    languages({ languages }) {
      return languages;
    },
    studyPart({ studyPart }) {
      return studyPart;
    },
    statusList({ statusList }) {
      return statusList;
    },
    provinceResult(state) {
      return state.provinceResult;
    },
    findresult(state) {
      return state.findresult;
    },
    masters(state) {
      return state.masters;
    },

    countstudent(state) {
      return state.countstudent;
    },
    countMaster(state) {
      return state.countMaster;
    },
    uncountstudent({ uncountstudent }) {
      return uncountstudent;
    },
    chartResult(state) {
      return state.chartResult;
    },
    magchartResult(state) {
      return state.magchartResult;
    },
    studyName(state) {
      return state.studyName;
    },
    country(state) {
      return state.country;
    },
    foreign(state) {
      return state.foreign;
    },

    types(state) {
      return state.types;
    },
    study(state) {
      return state.study;
    },
    getStudents(state) {
      return state.students;
    },
    oneStudent(state) {
      return state.oneStudent;
    },
    student({ student }) {
      return student;
    },
  },
  mutations: {
    provinceResult(state, payload) {
      state.provinceResult = payload;
    },
    findresult(state, payload) {
      state.findresult = payload;
    },
    chartResult(state, payload) {
      state.chartResult = payload;
    },
    magchartResult(state, payload) {
      state.magchartResult = payload;
    },
    addStudent(state, payload) {
      state.students.unshift(payload);
    },

    oneStudent(state, payload) {
      state.oneStudent = payload;
    },

    students(state, payload) {
      state.students = payload;
    },
    deleteStudent(state, payload) {
      state.students = state.students.filter((student) => {
        if (student._id == payload) return false;
        return student;
      });
    },
    upStudent(state, payload) {
      let index = state.students.findIndex((f) => f._id == payload._id);
      if (index !== -1) {
        state.students[index] = payload;
      }
    },
    upStudentList(state, payload) {
      state.students = state.students.map((student) => {
        if (student._id == payload._id) return payload;
        return student;
      });
    },

    checkStatus(state, payload) {
      state.oneStudent.status = payload.status;
    },
    countStudent(state, payload) {
      state.countstudent = payload;
    },
    uncountStudent(state, payload) {
      state.uncountstudent = payload;
    },
    countMaster(state, payload) {
      state.countMaster = payload;
    },
    allreport(state, payload) {
      state.allreport = payload;
    },
    masters(state, payload) {
      state.masters = payload;
    },
    student(state, payload) {
      state.student = payload;
    },
  },

  actions: {
    provinceResult(context) {
      axios
        .get(`${context.getters.url}/statistic/provincestatistic`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("provinceResult", res.data);
          }
        });
    },
    findresult(context, payload) {
      axios
        .get(`${context.getters.url}/student/search/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("findresult", res.data);
          }
        });
    },

    norm(context) {
      axios.get(`${context.getters.url}/student/norm`, {
        headers: context.getters.header,
      });
    },
    removeFile(context, payload) {
      return axios.post(`${context.getters.url}/student/files/delete`, {
        resFile: payload,
      });
    },
    sendSms(context, payload) {
      return axios.post(`${context.getters.url}/student/sendsms`, payload, {
        headers: context.getters.header,
      });
    },
    addStudent(context, payload) {
      return axios.post(
        `${context.getters.url}/student/registration`,
        payload,
        {
          headers: context.getters.header,
        }
      );
    },

    async getStudents({ getters, commit }, payload) {
      commit("students", []);
      commit("setLoading", true);
      let res = await axios.get(`${getters.url}/student`, {
        params: { ...payload },
        headers: getters.header,
      });
      // console.log(res.data);
      commit("students", res.data.student);
      commit("countStudent", res.data.count);
      commit("uncountStudent", res.data.uncount);
      commit("setLoading", false);
    },
    getMasters(context, payload) {
      return axios
        .get(`${context.getters.url}/student`, {
          params: { ...payload },
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("students", res.data.student);
            context.commit("countMaster", res.data.count);
          }
        });
    },

    oneStudent(context, payload) {
      context.commit("oneStudent", {});
      return axios
        .get(`${context.getters.url}/student/getone/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          // console.log(res.data)
          context.commit("oneStudent", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    activate(context, payload) {
      axios
        .get(`${context.getters.url}/student/activate/${payload.id}`, {
          headers: context.getters.header,
          params: payload,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("checkStatus", res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    cancelUser(context, payload) {
      axios
        .get(`${context.getters.url}/student/notaccepted/${payload.id}`, {
          headers: context.getters.header,
          params: payload,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("checkStatus", res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async waitingUser(context, payload) {
      await axios.get(`${context.getters.url}/student/waiting/${payload}`, {
        headers: context.getters.header,
      });
    },

    getStudent(context, payload) {
      return axios.get(`${context.getters.url}/student/getone/${payload}`, {
        headers: context.getters.header,
      });
    },
    editStudent(context, payload) {
      return axios.get(`${context.getters.url}/student/edit/${payload}`, {
        headers: context.getters.header,
      });
    },
    deleteStudent(context, payload) {
      axios
        .delete(`${context.getters.url}/student/archive/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("deleteStudent", payload);
          }
        });
    },
    allStudent(context) {
      axios
        .get(`${context.getters.url}/student`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            context.commit("students", res.data);
          }
        });
    },
    excelStudents(context, payload) {
      return axios.get(`${context.getters.url}/student/excel`, {
        params: { ...payload },
        headers: context.getters.header,
      });
    },
    async kvitansiya({ dispatch }, payload) {
      return await dispatch("getAxios", {
        url: `student/kvitansiya/${payload}`,
      });
    },

    saveStudent(context, payload) {
      return axios.post(
        `${context.getters.url}/student/save/${payload._id}`,
        payload,
        {
          headers: context.getters.header,
        }
      );
    },

    getChartResult(context, payload) {
      axios
        .get(`${context.getters.url}/statistic/student`, {
          headers: context.getters.header,
          params: payload,
        })
        .then((res) => {
          // console.log(res.data)
          context.commit("chartResult", res.data.result);
        });
    },
    getMagChartResult(context, payload) {
      axios
        .get(`${context.getters.url}/statistic/magistr`, {
          headers: context.getters.header,
          params: payload,
        })
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data)
            context.commit("magchartResult", res.data.result);
          }
        });
    },
    async studentEdit({ dispatch }, payload) {
      return await dispatch("getAxios", {
        url: `func/student/${payload}`,
      });
    },
    async upStudent({ dispatch }, payload) {
      return await dispatch("putAxios", {
        url: `func/student`,
        data: payload,
      });
    },

    async checkVaucherId({ getters }, payload) {
      return (await axios(`${getters.voucher_url}/voucher/getby/${payload}`))
        .data;
    },
  },
};
